<template>
  <b-button
    variant="success"
    class="btn-icon rounded-circle m-1"
    @click="$emit('click')"
  >
    <feather-icon icon="EditIcon" />
  </b-button>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  name: "EditButton",
  components: {
    BButton,
  },
};
</script>

<style scoped></style>
